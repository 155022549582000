import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Form, type FormProps, Input, Select, Spin } from 'antd';
import { client } from '../../useRequest';
import { useSelector } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';
import LoadingButton from '../../components/LoadingButton';
import _ from 'lodash';
import SelectTable from '../../components/formComponents/SelectTable';

type EditStoreyComponentProps = {
  onSubmit: any,
  initialValue?: any,
  loading: boolean,
}
const EditStoreyComponent: React.FC<EditStoreyComponentProps> = ({ onSubmit, initialValue, loading }) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      initialValues={initialValue}
      onFinish={(values: any) => onSubmit(values, form)}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please input name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Building"
        name="building_id"
        rules={[{ required: true, message: 'Please input building!' }]}
      >
        <SelectTable table='buildings' placeholder='Select Building' onChange={(val: any) => form.setFieldValue('building_id', val)} value={form.getFieldValue('building_id')} />
      </Form.Item>

      <Form.Item
        label="Description"
        name={'description'}
        rules={[{ required: false, message: 'Please input Description!' }]}
      >
        <TextArea rows={4} placeholder='Enter Description' />
      </Form.Item>

      <Form.Item>
        <LoadingButton loading={loading} title={_.isEmpty(initialValue) ? "Save" : "Update"} />
      </Form.Item>
    </Form>
  )
}


export default EditStoreyComponent
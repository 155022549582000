import React from 'react'
import { Button, Checkbox, Form as CustomForm, type FormProps, Input, Select, Spin } from 'antd';
import LoadingButton from '../../components/LoadingButton';


type EditAttributeComponentProp = {
  initialValue?: any,
  onSubmit: any,
  loading: boolean
}
const EditAttributeComponents: React.FC<EditAttributeComponentProp> = ({ initialValue, onSubmit, loading }) => {
  const [form] = CustomForm.useForm();
  return (
    <CustomForm
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      initialValues={initialValue}
      onFinish={onSubmit}
      autoComplete="off"
      layout="vertical"
    >

      <CustomForm.Item
        label="Owner Name"
        name={['attributes', 'owner_name']}
        rules={[{ required: true, message: 'Please input owner name!' }]}
      >
        <Input />
      </CustomForm.Item>

      <CustomForm.Item
        label="Driver Name"
        name={['attributes', 'driver_name']}
        rules={[{ required: true, message: 'Please input driver name!' }]}
      >
        <Input />
      </CustomForm.Item>
      <CustomForm.Item
        label="Licence"
        name={['attributes', 'licence']}
        rules={[{ required: true, message: 'Please input licence!' }]}
      >
        <Input />
      </CustomForm.Item>
     
      <CustomForm.Item>
        <LoadingButton loading={loading} title={"Update"} />
      </CustomForm.Item>
    </CustomForm>
  )
}

export default EditAttributeComponents
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    isAuth: false,
    token: {},
    error: ''
}

const authSlice = createSlice({
    name: 'auth-token',
    initialState,
    reducers:{
        loginSuccess: (state, {payload}) => {
            state.isAuth = true;
            state.token = payload;
            state.error = "";
        },
        loginFail: (state, {payload}) => {
            state.isAuth = false;
            state.token = {}
            state.error = payload;
        },
        logout: (state) => {
            state.isAuth = false;
            state.token = {}
        }
    }

})

const {reducer, actions} = authSlice;
export const {loginSuccess, loginFail, logout} = actions;
export default reducer;
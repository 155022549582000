import { Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { client2 } from '../../operationalRequest';
import _ from 'lodash';
import { ArrowRight, Building2Icon, ChevronRightSquareIcon, Cuboid, DotIcon, LandPlotIcon, LayoutPanelTop } from 'lucide-react';

type SelectDeviceLocationProps = {
    placeholder?: string;
    onChange?: any;
    value?: any;
    disabled?:boolean;
};

const SelectDeviceLocation: React.FC<SelectDeviceLocationProps> = ({ onChange, placeholder = 'Select', value, disabled=false }) => {
    const [options, setOptions] = useState([] as any);
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState('');
    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await client2.get(`api/device-location?&textSearch=${query}&value=${value}`);
            setOptions(recursiveData(response.data.data));
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const recursiveData = (device:any) => {
        let list = []as any;
        device.forEach((element:any) => {
            list.push({ value : `${element.id}|sites`, label: <span className='flex'> <LandPlotIcon className='size-4 mt-1' /> &nbsp; {element.name}</span> });
            if (element.buildings) {
                element.buildings.forEach((elemen:any) => {
                    list.push({ value : `${elemen.id}|buildings`, label: <span className='flex'>  &nbsp;  <Building2Icon className='size-4 mt-1' /> &nbsp; {elemen.name}</span> });

                    if (elemen.storeys) {
                        elemen.storeys.forEach((eleme:any) => {
                            list.push({ value : `${eleme.id}|storeys`, label: <span className='flex'> &nbsp;&nbsp;&nbsp;  <LayoutPanelTop className='size-4 mt-1' /> &nbsp; {eleme.name}</span> });

                            if (eleme.spaces) {
                                eleme.spaces.forEach((elem:any) => {
                                    list.push({ value : `${eleme.id}|spaces`, label: <span className='flex'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <Cuboid className='size-4 mt-1'/>  &nbsp;  {elem.name}</span> });
                                });
                            }
                        });
                    }
                });
            }
        });
        return list;
    };

    const handleSearch = useCallback(
        _.debounce((value) => {
            setQuery(value);
        }, 300),
        []
    );

    useEffect(() => {
        fetchData();
    }, [query, value]);
    console.log(value)
    return (
        <Select
            showSearch
            placeholder={placeholder}
            options={options}
            loading={loading}
            onSearch={handleSearch}
            onChange={onChange}
            filterOption={(input:any, option:any) =>
                option.label.props.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={disabled}
            value={options.find((item:any) => item.value === value)?.value}
        />
    );
};

export default SelectDeviceLocation;

import React, { useEffect, useState } from 'react';
import Layout from '../../components/layouts/Layout';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import SelectItems from '../../components/SelectItems';
import { Button, Input } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { client2 } from '../../operationalRequest';

const Prediction = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({} as any)
  const [date, setDate] = useState('' as any)

  const fetchData = async (i: any) => {
    setLoading(true);
    try {
      const response = await client2.get(`api/forcast/prediction?date=${i}`);
      const apiData = response.data?.data;
      const categories = apiData.map((item: any) => item.timestamp);
        const loadSeries = apiData.filter((item: any) => moment(item.timestamp).isBefore(moment())).map((it: any) => it.load);
        const predictionSeries = apiData.map((item: any) => item.prediction);
      setData({categories, loadSeries, predictionSeries})   
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false)
  };
  useEffect(() => {
    if(!!date){
      fetchData(date)
    }
  }, [date]);

  

    
  
    // Chart options
    const options = {
      chart: {
        type: 'line',
        height: 350,
        toolbar: {
          show: true
        }
      },
      xaxis: {
        categories: data?.categories,
        title: {
          text: 'Timestamp'
        }
      },
      yaxis: {
        title: {
          text: 'Value'
        }
      },
      stroke: {
        width: 2
      },
      markers: {
        size: 5
      },
      colors: ['#FF4560', '#008FFB'], // Colors for the lines
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'center'
      },
      dataLabels: {
        enabled: false,
        offsetY: -10, // Adjust the offsetY to add spacing between labels and data points
      },
    } as any;
  
    // Chart series
    const series = [
      {
        name: 'Load',
        data: data?.loadSeries
      },
      {
        name: 'Prediction',
        data: data?.predictionSeries
      }
    ];


  return (
    <Layout title="Prediction Forecast" loading={loading}>
        <div className="flex px-4 py-2 border rounded-md shadow-md mb-5 justify-start space-x-12">
        <Input type="date" onChange={(e:any) => setDate(e.target.value)} />
        </div>
       
        <div style={{ width: '100%', overflowX: 'auto' }}>
        <ReactApexChart options={options} series={series} type="area" height={350} />
      </div>
    </Layout>
  );
};

export default Prediction;
import React, { useState } from "react";
import { Form, Input } from "antd";
import LoadingButton from "../../../components/LoadingButton";
import { Eye as EyeIcon, EyeOff as EyeOffIcon } from "lucide-react";

type NewPasswordComponentProps = {
  onSubmit: any;
  initialValue?: any;
  loading: boolean;
  email: string;
};

const NewPasswordComponent: React.FC<NewPasswordComponentProps> = ({
  onSubmit,
  initialValue,
  loading,
  email,
}) => {
  const [form] = Form.useForm();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleToggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <>
      <h2 className="font-bold text-2xl text-green-800">
        Change your password?
      </h2>
      <p className="text-xs mt-4 text-green-800"></p>
      <Form
        form={form}
        name="basic"
        // labelCol={{ span: 8 }}
        initialValues={initialValue}
        onFinish={(values: any) => onSubmit(values, form)}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="New Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter a new password!",
            },
          ]}
        >
          <div style={{ position: "relative" }}>
            <Input
              type={passwordVisible ? "text" : "password"}
              name="password"
              style={{ height: "42px", fontSize: "16px" }}
            />
            <span
              onClick={handleTogglePasswordVisibility}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "10px",
                top: "10px",
              }}
            >
              {passwordVisible ? <EyeIcon /> : <EyeOffIcon />}
            </span>
          </div>
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please enter a Confirm password!",
            },
          ]}
        >
          <div style={{ position: "relative" }}>
            <Input
              type={confirmPasswordVisible ? "text" : "password"}
              name="confirmPassword"
              style={{ height: "42px", fontSize: "16px" }}
            />
            <span
              onClick={handleToggleConfirmPasswordVisibility}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "10px",
                top: "10px",
              }}
            >
              {confirmPasswordVisible ? <EyeIcon /> : <EyeOffIcon />}
            </span>
          </div>
        </Form.Item>
        <Form.Item>
          <LoadingButton loading={loading} title={"Change Password"} />
        </Form.Item>
      </Form>
    </>
  );
};
export default NewPasswordComponent;

import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import Datatable from "../../components/datatable/Datatable";
import {
  ArrowBigLeft,
  ArrowBigRightDash,
  CrossIcon,
  Delete,
  MapPinIcon,
  SidebarCloseIcon,
  Trash2Icon,
  X,
} from "lucide-react";
import { Button, Card, Divider, Modal, Popconfirm, Spin } from "antd";
import toastr from "toastr";
import OperationalDatatable from "../../components/datatable/OperationalDatatable";
import { client2 } from "../../operationalRequest";
import EditStorey from "./EditStorey";
import AddStorey from "./AddStorey";
import {
  CREATE_STOREY,
  UPDATE_STOREY,
  DESTROY_STOREY,
} from "../../utils/permission";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";

const Storeys = () => {
  const [split, setSplit] = useState(false);
  const [asset, setAsset] = useState(null as any);
  const [mutate, setMutate] = useState({} as any);
  const [locations, setLocations] = useState([] as any);

  const onDelete = async (id: any) => {
    const response = await client2.delete(`api/storey/${id}`);
    // if(response.status === 200){
    setMutate({ data: Math.random() });
    toastr.success("Storey Deleted Successfully");
    // }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Created By",
      dataIndex: "created_by.email",
      key: "email",
      sorter: false,
      render: (row: any, record: any) => {
        return record.created_by?.email;
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (row: any, record: any) => {
        return (
          <div className="flex justify-content-between">
            <PermissionCheck permission={UPDATE_STOREY}>
              <EditStorey data={record} mutate={setMutate} />
            </PermissionCheck>
            <PermissionCheck permission={DESTROY_STOREY}>
              <Popconfirm
                title="Delete the storey"
                description="Are you sure to delete this storey?"
                onConfirm={() => onDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size="small"
                  className="bg-gray-400 size-8 rounded-full py-3 flex items-center text-white"
                >
                  <Trash2Icon />
                </Button>
              </Popconfirm>
            </PermissionCheck>
          </div>
        );
      },
    },
  ];

  const handleInfo = (values: any) => {
    setLocations(
      values?.data?.data
        .filter((it: any) => {
          // console.log('attributes', !!it.attributes && !!it.attributes?.latitude, it.attributes)
          return (
            !!it.attributes &&
            it.attributes?.latitude &&
            it.attributes?.longitude
          );
        })
        .map((item: any, i: number) => {
          return {
            lat: item.attributes?.latitude,
            lng: item.attributes?.longitude,
            name: item.name,
          };
        })
    );
  };

  return (
    <Layout
      title={"Storeys"}
      breadcrumbs={["sites", "buildings", "storeys", "spaces"]}
    >
      <div className="flex">
        <OperationalDatatable
          setInfo={handleInfo}
          mutate={mutate}
          heading="Asset"
          addBody={
            <PermissionCheck permission={CREATE_STOREY}>
              <AddStorey mutate={setMutate} />
            </PermissionCheck>
          }
          url="api/storey"
          columns={columns}
          filters={{ includeCustomers: true }}
        />
      </div>
    </Layout>
  );
};

export default Storeys;

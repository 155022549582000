import { useState } from "react";
import { Button, Popconfirm } from "antd";
import {
  Check,
  CheckCircle,
  ForwardIcon,
  Trash2Icon,
  XCircle,
} from "lucide-react";
import toastr from "toastr";
import { client2 } from "../../operationalRequest";
import AddSubTenant from "./AddSubTenant";
import OperationalDatatable from "../../components/datatable/OperationalDatatable";
import Layout from "../../components/layouts/Layout";
import EditSubTenant from "./EditSubTenant";
import {
  CREATE_SUB_TENANT,
  UPDATE_SUB_TENANT,
  DESTROY_SUB_TENANT,
} from "../../utils/permission";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";

const Tenants = () => {
  const [mutate, setMutate] = useState({} as any);

  const onSendRequest = async (id: any) => {
    const response = await client2.get(`api/sub-tenant/send-request/${id}`);
    toastr.success(response.data.message);
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "firstname",
      sorter: true,
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastname",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      key: "last_login",
      sorter: true,
    },
    {
      title: "Verified",
      dataIndex: "email_verified_at",
      key: "email_verified_at",
      sorter: false,
      render: (row: any, record: any) => {
        return !!record.email_verified_at ? (
          <CheckCircle size={20} color={"green"} />
        ) : (
          <XCircle size={20} color="red" />
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (row: any, record: any) => {
        return (
          <div className="flex justify-content-between">
            {!record?.email_verified_at && (
              <Popconfirm
                title="Send Request"
                description="Are you sure to send request to mail?"
                onConfirm={() => onSendRequest(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  title="Send Request"
                  size="small"
                  className="bg-blue-400 size-8 rounded-full py-3 flex items-center text-white"
                >
                  <ForwardIcon />
                </Button>
              </Popconfirm>
            )}

            <PermissionCheck permission={UPDATE_SUB_TENANT}>
              <EditSubTenant data={record} mutate={setMutate} />
            </PermissionCheck>

            {/* <Popconfirm
            title="Delete the storey"
            description="Are you sure to delete this storey?"
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button size='small' className='bg-gray-400 size-8 rounded-full py-3 flex items-center text-white'><Trash2Icon /></Button>

          </Popconfirm> */}
          </div>
        );
      },
    },
  ];

  const onDelete = async (id: any) => {};

  return (
    <Layout title={"Sub Tenants"}>
      <div className="flex">
        <OperationalDatatable
          mutate={mutate}
          heading="Asset"
          addBody={
            <PermissionCheck permission={CREATE_SUB_TENANT}>
              <AddSubTenant mutate={setMutate} />
            </PermissionCheck>
          }
          url="api/sub-tenants"
          columns={columns}
          filters={{ includeCustomers: true }}
        />
      </div>
    </Layout>
  );
};

export default Tenants;

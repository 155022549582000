import React from 'react';
import SidebarComponent from './components/layouts/SidebarComponent';
import Login from './page/guest/Login';
import AllRoutes from './AllRoutes';


function App() {
  return (
    <div className="App">
      {/* <SidebarComponent/> */}
      <AllRoutes/>
    </div>
  );
}

export default App;

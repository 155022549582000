import React, { useEffect, useState } from 'react';
import { MapContainer as LeafletMap, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Importing marker icon assets
const defaultIcon = L.icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
});

L.Marker.prototype.options.icon = defaultIcon;

type LeafMapProps = {
    position: any
}

const LeafMap: React.FC<LeafMapProps> = ({ position }) => {
    const [pose, setPose] = useState([0, 0] as any);

    useEffect(() => {
        setTimeout(() => setPose(position), 1000);
    }, [position]);

    return (
        <LeafletMap key={pose.toString()} center={pose} zoom={16} style={{ height: '500px' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={pose} />
        </LeafletMap>
    );
}

export default LeafMap;

import { Button, Drawer, Tabs } from "antd";
import { ArrowBigRightDash } from "lucide-react";
import React, { useEffect, useState } from "react";
import EditStoreyComponent from "./EditSpaceComponent";
import { client } from "../../useRequest";
import toastr from "toastr";
import { client2 } from "../../operationalRequest";
import EditAttributeComponents from "./EditAttributeComponent";
import EditSpaceComponent from "./EditSpaceComponent";

type EditSpaceProps = {
  data: any;
  mutate?: any;
};
const EditSpace: React.FC<EditSpaceProps> = ({ data, mutate }) => {
  const [open, setOpen] = useState(false);
  const [attributes, setAttributes] = useState({} as any);
  const [loading, setLoading] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const editSpace = async (values: any) => {
    try {
      if (loading) return;
      setLoading((curr: any) => true);
      const response = await client2.put(`api/space/${data?.id}`, {
        space: values,
      });
      if (response.status === 200) {
        mutate({ data: "data" });
        setOpen(false);
        toastr.success("Space Updated Successfully");
      }
    } catch (e: any) {
      console.log(e);
    }
    setLoading((curr: any) => false);
  };

  const editAttribute = async (values: any) => {
    try {
      if (loading) return;
      setLoading((curr: any) => true);
      const response = await client2.put(`api/building/${data?.id}`, {
        ...values,
      });
      if (response.status === 200) {
        mutate({ data: response.data });
        setOpen(false);
        toastr.success("Asset Attribute Updated Successfully");
      }
    } catch (e: any) {
      console.log(e);
    }
    setLoading((curr: any) => false);
  };

  return (
    <>
      <Button
        onClick={showDrawer}
        size="small"
        className={`bg-blue-500 size-8 rounded-full py-3 flex items-center`}
      >
        <ArrowBigRightDash />
      </Button>
      <Drawer size="large" title="Edit Space" onClose={onClose} open={open}>
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: "Space",
              key: "1",
              children: (
                <EditSpaceComponent
                  loading={loading}
                  initialValue={{ ...data }}
                  onSubmit={editSpace}
                />
              ),
            },
            // {
            //     label: 'Attributes',
            //     key: '2',
            //     children: <EditAttributeComponents loading={loading} initialValue={{ attributes: data.attributes }} onSubmit={editAttribute} />,
            // },
          ]}
        />
      </Drawer>
    </>
  );
};

export default EditSpace;

import { Button, Spin, Table, Form as CustomForm, Input, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { client2 } from "../../../operationalRequest";
import toastr from "toastr";
import LoadingButton from "../../../components/LoadingButton";
import SelectTable from "../../../components/formComponents/SelectTable";

type FetchPlantProps = {
  id: number;
  connected: boolean;
  setConnected: any;
};
const FetchPlant: React.FC<FetchPlantProps> = ({
  id,
  connected,
  setConnected,
}) => {
  const [data, setData] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [form] = CustomForm.useForm();

  const fetchStation = async () => {
    setLoading(true);
    try{
      const response = await client2.get(`api/fetch-deddie-plant/${id}`);
      if (response.data.status === 401) {
        setConnected(false);
      }
      if (response.status === 200) {
        setConnected(true);
        setData(response.data.data);
      }
      if (response.data.status === 400) {
        toastr.error(response.data.message);
      }
      setLoading(false);
    }catch(e:any){

    }
  };

  const importPlant = async () => {
    setLoading(true);
    const record = data.filter((item: any) => item.checked);
    try {
      const response = await client2.post(`api/deddie/import/${id}`, {
        ...record,
      });
      if (response.status === 200) {
        toastr.success("Devices Imported Successfully");
        form.resetFields();
        setConnected(false);
      } else {
        toastr.error("Bad Request");
      }
    } catch (e: any) {}
    setLoading(false);
  };

  useEffect(() => {
    fetchStation();
  }, [connected]);

  const columns = [
    {
      title: "Supply Number",
      dataIndex: "supplyNumber",
      key: "supplyNumber",
      sorter: false,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: false,
    },
    {
      title: "Import",
      key: "import",
      render: (row: any, record: any) => (
        <Checkbox
          checked={record.checked}
          onChange={(e) => handleCheckboxChange(e, record.supplyNumber)}
        />
      ),
    },
    {
      title: "Site",
      key: "site",
      render: (row: any, record: any) => (
        <SelectTable
          disabled={!record?.checked}
          table="sites"
          placeholder="Select Site"
          value={record?.site}
          onChange={(e: any) =>
            handleInputChange(e, record.supplyNumber, "site")
          }
        />
      ),
    },
    {
      title: "System",
      key: "system",
      render: (row: any, record: any) => (
        <SelectTable
          disabled={!record?.checked}
          table="systems"
          placeholder="Select System"
          value={record?.system}
          onChange={(e: any) =>
            handleInputChange(e, record.supplyNumber, "system")
          }
        />
      ),
    },
  ];

  const handleCheckboxChange = (e: any, key: any) => {
    const newData = data.map((item: any) => {
      if (item.supplyNumber === key) {
        return { ...item, checked: e.target.checked };
      }
      return item;
    });
    setData(newData);
  };

  const handleInputChange = (e: any, key: any, type: string) => {
    const newData = data.map((item: any) => {
      if (item.supplyNumber === key) {
        return { ...item, [`${type}`]: e };
      }
      return item;
    });
    setData(newData);
  };

  const handleSubmit = () => {
    const record = data.filter((item: any) => item.checked);
    console.log("Submitted:", record);
  };

  return (
    <>
      {connected && (
        <Table
          pagination={false}
          scroll={{ y: 500 }}
          loading={{
            spinning: loading,
            indicator: <Spin size="large" style={{ color: "green" }} />,
          }}
          className="w-full drop-shadow-sm shadow-md"
          dataSource={data}
          columns={columns}
          rowKey={(record: any) => record.supplyNumber}
          // onChange={handleSort}
        />
      )}
      <br />
      <Button onClick={importPlant}>Import</Button>
    </>
  );
};

export default FetchPlant;

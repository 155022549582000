import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { client } from '../../useRequest'
import toastr from "toastr";
import { PlusIcon } from 'lucide-react'
import { Modal } from 'antd'
import { client2 } from '../../operationalRequest';
import EditStoreyComponent from './EditSpaceComponent';

type AddStoreyProps = {
  mutate?: any
}
const AddStorey: React.FC<AddStoreyProps> = ({ mutate }) => {
  const [profile, setProfile] = useState({} as any)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);
  // const fetchOptions = async () => {
  //   const profile = await client.get(`api/assetProfileInfo/default`)
  //   setProfile(profile.data.id)
  // }

  const onSubmit = async (values: any, form: any) => {
    console.log(process.env.REACT_APP_OPERATIONAL_URL,)
    try {
      if (loading) return;
      setLoading((curr: any) => true)
      const response = await client2.post('api/space', {
        ...values
      })
    
        mutate({ data: response.data });
        setOpen(false)
        toastr.success("Space Created Successfully");
      form.resetFields();
    } catch (e: any) {
      console.log(e)
    }
    setLoading((curr: any) => false)
  }

  // useEffect(() => {
  //   fetchOptions();
  // }, [])
  return (
    <>
      <a onClick={() => setOpen(true)}>
        <PlusIcon size={30} color='white' strokeWidth={2} className=' rounded-full cursor-pointer bg-gray-500 transition duration-300 ease-in-out hover:bg-gray-400 hover:text-white' />
      </a>
      <Modal
        title={'Add Space'}
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={800}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <EditStoreyComponent loading={loading} onSubmit={onSubmit} />
      </Modal>
    </>
  )
}

export default AddStorey
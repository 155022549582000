import { Button, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { client2 } from "../../../operationalRequest";
import toastr from "toastr";

type FetchPlantProps = {
    id: number,
    connected: boolean,
    setConnected: any
}
const FetchPlant: React.FC<FetchPlantProps> = ({ id, connected, setConnected }) => {

    const [data, setData] = useState([] as any);
    const [loading, setLoading] = useState(false)

    const fetchStation = async () => {
        setLoading(true)
        try {
            const response = await client2.get(`api/fetch-goodwe-plant/${id}`);
            if (response.data.status === 401) {
                setConnected(false)
            }
            if (response.status === 200) {
                setConnected(true);
                setData(response.data.data)
            }
            if (response.status === 400) {
                setConnected(false)
                toastr.error(response.data.message);
            }
            setConnected(false)
        } catch (e: any) {
        }
    }

    const importPlant = async () => {
        setLoading(true)
        const response = await client2.get(`api/goodwe/import/${id}`)
        if (response.status === 200) {
            toastr.success("Devices Imported Successfully");
            setConnected(false);

        } else {
            toastr.error("Bad Request");

        }
        setLoading(false)
    }

    useEffect(() => {
        fetchStation();
    }, [connected])

    const columns = [
        {
            title: 'PlantId',
            dataIndex: 'plantId',
            key: 'plantId',
            sorter: false
        },
        {
            title: 'Name',
            dataIndex: 'plantName',
            key: 'planeName',
            sorter: false
        },

        {
            title: 'Classification',
            dataIndex: 'classification',
            key: 'classification',
            sorter: false
        },
        {
            title: 'Latitude',
            dataIndex: 'latitude',
            key: 'latitude',
            sorter: false
        },
        {
            title: 'Longitude',
            dataIndex: 'longitude',
            key: 'longitude',
            sorter: false
        },
        {
            title: 'Capacity',
            dataIndex: 'capacity',
            key: 'capacity',
            sorter: false
        },
    ];

    return (
        <>
            {connected && (

                <>
                    <Table pagination={false}
                        scroll={{ y: 500 }}
                        loading={{
                            spinning: loading,
                            indicator: <Spin size="large" style={{ color: 'green' }} />,
                        }}
                        className='w-full drop-shadow-sm shadow-md'
                        dataSource={data}
                        columns={columns}

                        rowKey={(record: any) => record.plantCode}
                    // onChange={handleSort}
                    />
                    <br />
                    <Button disabled={loading}  onClick={() => {
                        importPlant();
                    }}>Import {loading && <Spin/>}</Button>
                </>
            )}
        </>
    )
}

export default FetchPlant

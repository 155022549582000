import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import Datatable from "../../components/datatable/Datatable";
import {
  ArrowBigLeft,
  ArrowBigRightDash,
  CrossIcon,
  Delete,
  MapPinIcon,
  SidebarCloseIcon,
  Trash2Icon,
  X,
} from "lucide-react";
import { Button, Card, Divider, Modal, Popconfirm, Spin } from "antd";
import { client } from "../../useRequest";
// import L from 'leaflet';
import toastr from "toastr";
import OperationalDatatable from "../../components/datatable/OperationalDatatable";
import LeafMap from "../../components/LeafMap";
import { client2 } from "../../operationalRequest";
import { random } from "lodash";
import MapLeaf from "../../components/MapLeaf";
import EditSite from "./EditSite";
import AddSite from "./AddSite";
import { CREATE_SITE, DESTROY_SITE, UPDATE_SITE } from "../../utils/permission";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";

const Sites = () => {
  const [split, setSplit] = useState(false);
  const [asset, setAsset] = useState(null as any);
  const [mutate, setMutate] = useState({} as any);
  const [locations, setLocations] = useState([] as any);

  const onDelete = async (id: any) => {
    const response = await client2.delete(`api/site/${id}`);
    // if(response.status === 200){
    setMutate({ data: Math.random() });
    toastr.success("Site Deleted Successfully");
    // }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Created By",
      dataIndex: "created_by.email",
      key: "email",
      sorter: false,
      render: (row: any, record: any) => {
        return record.created_by?.email;
      },
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      render: (row: any, record: any) => {
        return <>{record.attributes?.country}</>;
      },
    },
    {
      title: "Street Name",
      dataIndex: "street_name",
      key: "street_name",
      render: (row: any, record: any) => {
        return <>{record.attributes?.street_name}</>;
      },
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (row: any, record: any) => {
        return <>{record.attributes?.city}</>;
      },
    },
    {
      title: "Facility Manager",
      dataIndex: "ownerName",
      key: "ownerName",
      render: (row: any) => {
        <div></div>;
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (row: any, record: any) => {
        return (
          <div className="flex justify-content-between">
            <Button
              disabled={
                !record.attributes ||
                !record.attributes?.latitude ||
                !record.attributes?.longitude
              }
              onClick={() => {
                setSplit(true);
                setAsset(record);
              }}
              size="small"
              className={`size-8  rounded-full py-3 flex items-center`}
            >
              <MapPinIcon />
            </Button>
            <PermissionCheck permission={UPDATE_SITE}>
              <EditSite data={record} mutate={setMutate} />
            </PermissionCheck>
            <PermissionCheck permission={DESTROY_SITE}>
              <Popconfirm
                title="Delete Site Confirmation"
                description={
                  <div>
                    Deleting this site will also remove all associated children
                    and relationships.
                    <br />
                    Are you sure you want to proceed?
                  </div>
                }
                onConfirm={() => onDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size="small"
                  className="bg-gray-400 size-8 rounded-full py-3 flex items-center text-white"
                >
                  <Trash2Icon />
                </Button>
              </Popconfirm>
            </PermissionCheck>
          </div>
        );
      },
    },
  ];

  const handleInfo = (values: any) => {
    setLocations(
      values?.data?.data
        .filter((it: any) => {
          // console.log('attributes', !!it.attributes && !!it.attributes?.latitude, it.attributes)
          return (
            !!it.attributes &&
            it.attributes?.latitude &&
            it.attributes?.longitude
          );
        })
        .map((item: any, i: number) => {
          return {
            lat: item.attributes?.latitude,
            lng: item.attributes?.longitude,
            name: item.name,
          };
        })
    );
  };

  return (
    <Layout
      title={"Sites"}
      breadcrumbs={["sites", "buildings", "storeys", "spaces"]}
    >
      <MapLeaf locations={locations} />
      <div className="flex">
        <OperationalDatatable
          setInfo={handleInfo}
          mutate={mutate}
          heading="Site"
          addBody={
            <PermissionCheck permission={CREATE_SITE}>
              <AddSite mutate={setMutate} />
            </PermissionCheck>
          }
          url="api/site"
          columns={columns}
          filters={{ includeCustomers: true }}
        />
        <SiteAttribute
          onClick={() => setSplit(false)}
          asset={asset}
          expanded={split}
        />
      </div>
    </Layout>
  );
};

export default Sites;

type SiteAttributeProps = {
  asset: any;
  expanded: boolean;
  onClick: any;
};
const SiteAttribute: React.FC<SiteAttributeProps> = ({
  asset,
  expanded,
  onClick,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!!asset?.attributes && expanded) {
      setLoading(false);
    }
  }, [asset, expanded]);

  if (!expanded) {
    return <></>;
  }
  return (
    <Modal
      okButtonProps={{ style: { display: "none" } }}
      width={"auto"}
      title="Building"
      open={expanded}
      onCancel={onClick}
    >
      <Spin className="flex justify-start" spinning={loading}>
        <Card className="absolute z-[10000] m-5 ml-16 p-2 drop-shadow-md shadow-lg max-w-[300px]">
          {Object.entries(asset?.attributes || {}).map(
            ([key, value]: any, i) => {
              return (
                <p key={i}>
                  <span>
                    <strong>{key.toUpperCase()}</strong>: {value}{" "}
                  </span>
                </p>
              );
            }
          )}
        </Card>
        {!!asset?.attributes && (
          <LeafMap
            position={[asset.attributes.latitude, asset.attributes.longitude]}
          />
        )}
      </Spin>
    </Modal>
  );
};

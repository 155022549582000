import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Form as CustomForm, type FormProps, Input, Select, Spin } from 'antd';
import { client } from '../../useRequest';
import { useSelector } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';
import LoadingButton from '../../components/LoadingButton';
import _ from 'lodash';

type EditSiteComponentProps = {
    onSubmit: any,
    initialValue?: any,
    loading: boolean,
}
const EditSiteComponent: React.FC<EditSiteComponentProps> = ({onSubmit, initialValue, loading}) => {
    const [form] = CustomForm.useForm();

  return (
    <CustomForm
    form={form}
    name="basic"
    labelCol={{ span: 8 }}
    initialValues={initialValue}
    onFinish={(values: any) =>onSubmit(values, form)}
    autoComplete="off"
    layout="vertical"
  >
    <CustomForm.Item
      label="Name"
      name="name"
      rules={[{ required: true, message: 'Please input name!' }]}
    >
      <Input />
    </CustomForm.Item>
    <CustomForm.Item
      label="Description"
      name={'description'}
      rules={[{ required: false, message: 'Please input Description!' }]}
    >
      <TextArea rows={4} placeholder='Enter Description' />
    </CustomForm.Item>

    <CustomForm.Item>
    <LoadingButton loading={loading} title={_.isEmpty(initialValue) ? "Save" : "Update"}/>
    </CustomForm.Item>
  </CustomForm>
  )
}


export default EditSiteComponent
import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form as CustomForm,
  type FormProps,
  Input,
  Select,
  Spin,
} from "antd";
import { client } from "../../useRequest";
import { useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import LoadingButton from "../../components/LoadingButton";
import _ from "lodash";
import SelectTable from "../../components/formComponents/SelectTable";
import SelectDeviceLocation from "../../components/formComponents/SelectDeviceLocation";
import CustomSelectOptions from "../../components/formComponents/CustomSelectOptions";

type EditSpaceComponentProps = {
  onSubmit: any;
  initialValue?: any;
  loading: boolean;
};
const EditSpaceComponent: React.FC<EditSpaceComponentProps> = ({
  onSubmit,
  initialValue,
  loading,
}) => {
  const [form] = CustomForm.useForm();
  console.log("ini", initialValue);
  return (
    <CustomForm
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      initialValues={initialValue}
      onFinish={(values: any) => onSubmit(values, form)}
      autoComplete="off"
      layout="vertical"
    >
      <CustomForm.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input name!" }]}
      >
        <Input disabled={form.getFieldValue("imported")} />
      </CustomForm.Item>
      <CustomForm.Item
        label="Location"
        name="location_id"
        rules={[{ required: true, message: "Please input location!" }]}
      >
        <SelectDeviceLocation
          disabled={form.getFieldValue("imported")}
          placeholder="Select Location"
          onChange={(val: any) => form.setFieldValue("location_id", val)}
          value={form.getFieldValue("location_id")}
        />
      </CustomForm.Item>
      <CustomForm.Item
        label="Device Type"
        name="device_type"
        rules={[{ required: true, message: "Please input type!" }]}
      >
        <CustomSelectOptions
          options={[
            { value: 10, label: "Inverter" },
            { value: 20, label: "Grid Electrical Meter" },
            { value: 30, label: "Electrical Meter" },
            { value: 40, label: "Battery" },
            { value: 50, label: "Load" },
            { value: 100, label: "Unknown" },
          ]}
          disabled={form.getFieldValue("imported")}
          onChange={(val: any) => form.setFieldValue("device_type", val)}
        />
      </CustomForm.Item>
      <CustomForm.Item
        label="Manufacture"
        name="manufacture_type"
        rules={[{ required: true, message: "Please input type!" }]}
      >
        <CustomSelectOptions
          options={[
            { value: "1", label: "Huawei" },
            { value: "2", label: "GoodWe" },
            { value: "3", label: "DEDDIE" },
            { value: "30", label: "Custom" },
          ]}
          disabled={form.getFieldValue("imported")}
          onChange={(val: any) => form.setFieldValue("manufacture_type", val)}
        />
      </CustomForm.Item>

      <CustomForm.Item
        label="System"
        name="system_id"
        rules={[{ required: true, message: "Please input system!" }]}
      >
        <SelectTable
          disabled={form.getFieldValue("imported")}
          table="systems"
          placeholder="Select System"
          onChange={(val: any) => form.setFieldValue("system_id", val)}
          value={form.getFieldValue("system_id")}
        />
      </CustomForm.Item>

      <CustomForm.Item
        label="Description"
        name={"description"}
        rules={[{ required: false, message: "Please input Description!" }]}
      >
        <TextArea rows={4} placeholder="Enter Description" />
      </CustomForm.Item>

      <CustomForm.Item>
        <LoadingButton
          loading={loading}
          title={_.isEmpty(initialValue) ? "Save" : "Update"}
        />
      </CustomForm.Item>
    </CustomForm>
  );
};

export default EditSpaceComponent;

import { Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { client2 } from "../../operationalRequest";
import _ from "lodash";

type SelectTableProps = {
  placeholder?: string;
  onChange?: any;
  table: string;
  value?: any;
  disabled?: boolean;
  filter?: any;
  multiple?: boolean;
};

const SelectTable: React.FC<SelectTableProps> = ({
  onChange,
  placeholder = "Select",
  table,
  value,
  disabled = false,
  filter = {},
  multiple = false,
}) => {
  const [options, setOptions] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await client2.get(
        `api/select-table?table=${table}&textSearch=${query}&value=${
          Array.isArray(value) ? value.join(",") : value
        }&filter=${JSON.stringify(filter)}`
      );
      setOptions(
        response?.data?.data.map((item: any) => {
          const { id, name, ...rest } = item;
          return { ...rest, value: id, label: name };
        })
      );
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = useCallback(
    _.debounce((value) => {
      setQuery(value);
    }, 300),
    []
  );

  useEffect(() => {
    fetchData();
  }, [query, value]);

  return (
    <Select
      mode={multiple ? "multiple" : undefined}
      className="w-full"
      showSearch
      placeholder={placeholder}
      options={options}
      loading={loading}
      onSearch={handleSearch}
      onChange={onChange}
      filterOption={(input: any, option: any) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      value={
        multiple
          ? options
              .filter((option: any) => value?.includes(option.value))
              .map((option: any) => option.value)
          : options.find((it: any) => it.value === value)?.value
      }
      disabled={disabled}
    />
  );
};

export default SelectTable;

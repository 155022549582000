import { Form, Input } from 'antd'
import React from 'react'

type TextFieldProps = {
    label: string,
    name: any,
    rules: any,
    type?: string
}

const TextField: React.FC<TextFieldProps> = ({label, name, rules, type="text"}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      className='w-full'
    >
      <Input type={type} />
    </Form.Item>
  )
}

export default TextField

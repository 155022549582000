import React, { useState } from "react";
import Layout from "../../components/layouts/Layout";
import OperationalDatatable from "../../components/datatable/OperationalDatatable";
import AddDevice from "./AddDevice";
import EditDevice from "./EditDevice";
import DeleteButton from "../../components/authorizeComponent/DeleteButton";
import { CREATE_SYSTEM, DESTROY_SYSTEM, UPDATE_SYSTEM } from "../../utils/permission";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";

const Devices = () => {
  const [mutate, setMutate] = useState({} as any);



  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Created By",
      dataIndex: "created_by.email",
      key: "email",
      sorter: false,
      render: (row: any, record: any) => {
        return record.created_by?.email;
      },
    },
    {
      title: "System Type",
      dataIndex: "type",
      key: "type",
      sorter: false,
      render: (row: any, record: any) => {
        const systemId = record.type;
        switch (systemId) {
          case 1:
            return "Energy System";
          case 2:
            return "Pv Energy System";
          case 3:
            return "Energy Storage";
          case 4:
            return "Energy Consumption";
          case 5:
            return "Grid Point";
          default:
            return "Unknown";
        }
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (row: any, record: any) => {
        return (
          <div className="flex justify-content-between">
            <PermissionCheck permission={UPDATE_SYSTEM} >
              <EditDevice data={record} mutate={setMutate} />
            </PermissionCheck>
            <DeleteButton model="systems" title="system" id={record.id} setMutate={setMutate} permission={DESTROY_SYSTEM} />
          </div>
        );
      },
    },
  ];

  return (
    <Layout title={"Systems"}>
      <OperationalDatatable
        heading="System"
        url="api/systems"
        columns={columns}
        filters={{ includeCustomers: true }}
        mutate={mutate}
        addBody={
          <PermissionCheck permission={CREATE_SYSTEM}>
            <AddDevice mutate={setMutate} />
          </PermissionCheck>}
      />
    </Layout>
  );
};

export default Devices;

import React from 'react'
import { Button, Checkbox, Form as CustomForm, type FormProps, Input, Select, Spin } from 'antd';
import LoadingButton from '../../components/LoadingButton';


type EditAttributeComponentProp = {
  initialValue?: any,
  onSubmit: any,
  loading: boolean
}
const EditAttributeComponents: React.FC<EditAttributeComponentProp> = ({ initialValue, onSubmit, loading }) => {
  const [form] = CustomForm.useForm();
  return (
    <CustomForm
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      initialValues={initialValue}
      onFinish={onSubmit}
      autoComplete="off"
      layout="vertical"
    >

      <CustomForm.Item
        label="Street Name"
        name={['attributes', 'street_name']}
        rules={[{ required: true, message: 'Please input street name!' }]}
      >
        <Input />
      </CustomForm.Item>

      <CustomForm.Item
        label="Street Number"
        name={['attributes', 'street_number']}
        rules={[{ required: true, message: 'Please input street number!' }]}
      >
        <Input />
      </CustomForm.Item>
      <CustomForm.Item
        label="Country"
        name={['attributes', 'country']}
        rules={[{ required: true, message: 'Please input country!' }]}
      >
        <Input />
      </CustomForm.Item>
      <CustomForm.Item
        label="City"
        name={['attributes', 'city']}
        rules={[{ required: true, message: 'Please input city!' }]}
      >
        <Input />
      </CustomForm.Item>
      <CustomForm.Item
        label="Zip"
        name={['attributes', 'zip']}
        rules={[{ required: true, message: 'Please input zip!' }]}
      >
        <Input />
      </CustomForm.Item>
      <CustomForm.Item
        label="Latitude"
        name={['attributes', 'latitude']}
        rules={[{ required: true, message: 'Please input latitude!' }, {
          pattern: /^[0-9]+(\.[0-9]+)?$/,
          message: 'Please enter a valid number (e.g., 123.45).',
        }]}
      >
        <Input step={0.00} />
      </CustomForm.Item>
      <CustomForm.Item
        label="Longitude"
        name={['attributes', 'longitude']}
        rules={[{ required: true, message: 'Please input longitude!' }, {
          pattern: /^[0-9]+(\.[0-9]+)?$/,
          message: 'Please enter a valid number (e.g., 123.45).',
        }]}
      >
        <Input step={0.00} />
      </CustomForm.Item>
      <CustomForm.Item>
        <LoadingButton loading={loading} title={"Update"} />
      </CustomForm.Item>
    </CustomForm>
  )
}

export default EditAttributeComponents
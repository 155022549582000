import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

const PrivateRoute = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuth } = useSelector((state: any) => state.auth);
    // const { role } = useSelector((state: any) => state.roles);
  
    // React.useEffect(() => {
    //   if (!role) {
    //     navigate("/");
    //     isAuth && dispatch(loginSuccess());
    //   }
    // }, [isAuth, dispatch, role]);
  
    return !!isAuth ? <Outlet /> : <Navigate to="/" />;
}

export default PrivateRoute
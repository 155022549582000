import useSWR from 'swr'
import axios from 'axios'
import toastr from "toastr";
import { logout } from './store/slice/authSlice';
import store from './store/store';


const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 60000,
  headers,
})

client.interceptors.response.use(
  response => {
        return response
  },
  function(error) {
    if(error.response?.status === 401){
      store.dispatch(logout());
    }
    toastr.error(error.response?.data?.message ?? error.response?.message);
    return Promise.reject(error.response)
  }
)

client.interceptors.request.use(
  (config) => {
    const accessToken = store.getState().auth.token?.token;
    if(!!accessToken){
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);


const uploadMedia = async (files, url, fields = {}) => {
  const formData = new FormData()
  files.forEach(f => {
    formData.append('files[]', f)
  })

  for (const property in fields) {
    formData.append(property, fields[property])
  }

  const response = await client.post(url, formData)
  return response
}

const downloadBlobFile = async (apiUrl, fileName) => {
  const response = await client.get(apiUrl, { responseType: "blob" });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export default function useRequest(request, { initialData, ...config } = {}) {
    const { data: response, error, isValidating, mutate: originalMutate } = useSWR(
      request && JSON.stringify(request),
      () => client(request || {}),
      {
        ...config,
        suspense: false,
        revalidateOnFocus: false,
        revalidateIfStale: true,
        revalidateOnMount: true,
        initialData: initialData && {
          status: 200,
          statusText: 'InitialData',
          headers: {},
          data: initialData,
        },
      }
    )

    return {
      data: response && response.data,
      response,
      error,
      isValidating,
      mutate: update => {
        return originalMutate(response => ({
          ...response,
          data: { ...response.data },
        }))
      },
    }
  }

export { client, uploadMedia, downloadBlobFile }
import axios from 'axios';
import { config } from './smartCarConfig';
import { getReadProperties } from './carUtils';
import { client2 } from '../operationalRequest';

let api = {};



api.exchangeCode = async (code, id) => {
  // the backend will attach the accessToken as a session cookie, which will be needed to make requests to vehicle endpoints
  return await client2.get(`api/smartcar/exchange?code=${code}&id=${id}`);
};

api.getVehicles = async () => {
  const vehicleProperties = getReadProperties().join('.');
  const { data } = await client2.get(`api/smartcar/vehicles`, {
    params: {
      vehicleProperties,
      unitSystem: config.unitSystem,
    },
  });
  return data;
};

api.getVehicle = async (vehicleId, make) => {
  const vehicleProperties = getReadProperties().join('.');
  const { data } = await client2.get(`/vehicle`, {
    params: {
      vehicleId,
      make,
      vehicleProperties,
      unitSystem: config.unitSystem,
    },
  });
  return data;
};

api.controlCharge = async (vehicleId, action) => {
  return await client2.post(
    '/vehicle/charge',
    { action },
    {
      params: { vehicleId },
    }
  );
};

api.setChargeLimit = async (vehicleId, limit) => {
  return await client2.post(
    '/vehicle/charge-limit',
    { limit },
    {
      params: { vehicleId },
    }
  );
};

api.setAmperage = async (vehicleId, amperage, make) => {
  return await client2.post(
    '/vehicle/amperage',
    { amperage },
    {
      params: { vehicleId, make },
    }
  );
};

api.security = async (vehicleId, action) => {
  return await client2.post(
    '/vehicle/security',
    { action },
    {
      params: { vehicleId },
    }
  );
};

api.disconnect = async (vehicleId) => {
  return await client2.delete('/vehicle', {
    params: { vehicleId },
  });
};

api.disconnectAll = async () => {
  return await client2.delete('/vehicles');
};

export default api;
import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import Datatable from "../../components/datatable/Datatable";
import {
  ArrowBigLeft,
  ArrowBigRightDash,
  CrossIcon,
  Delete,
  Info,
  MapPinIcon,
  SidebarCloseIcon,
  Trash2Icon,
  X,
} from "lucide-react";
import { Button, Card, Divider, Modal, Popconfirm, Spin } from "antd";
import { client } from "../../useRequest";
import toastr from "toastr";
import OperationalDatatable from "../../components/datatable/OperationalDatatable";
import LeafMap from "../../components/LeafMap";
import { client2 } from "../../operationalRequest";
import _, { random } from "lodash";
import AddVehicle from "./AddVehicle";
import EditVehicle from "./EditVehicle";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  CREATE_FLEET,
  UPDATE_FLEET,
  DESTROY_FLEET,
} from "../../utils/permission";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";

const Vehicle = () => {
  const [split, setSplit] = useState(false);
  const [asset, setAsset] = useState(null as any);
  const [mutate, setMutate] = useState({} as any);
  const { user } = useSelector((state: any) => state.user);

  const onDelete = async (id: any) => {
    const response = await client2.delete(`api/vehicle/${id}`);
    // if(response.status === 200){
    setMutate({ data: Math.random() });
    toastr.success("Vehicle Deleted Successfully");
    // }
  };

  //   const connectVehicle = async() => {
  //     const response = await client2.get('api/smartcar/authorize');
  //     console.log('response', response);
  //   }
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "VIN",
      dataIndex: "vin",
      key: "vin",
      sorter: true,
    },
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      sorter: true,
    },
    {
      title: "Owner Name",
      dataIndex: "owner_name",
      key: "owner_name",
      render: (row: any, record: any) => {
        return <>{record.attributes?.owner_name}</>;
      },
    },
    {
      title: "Driver Name",
      dataIndex: "driver_name",
      key: "driver_name",
      render: (row: any, record: any) => {
        return <>{record.attributes?.driver_name}</>;
      },
    },
    {
      title: "Licence Plate",
      dataIndex: "licence",
      key: "licence",
      render: (row: any, record: any) => {
        return <>{record.attributes?.licence}</>;
      },
    },
    {
      title: "Created By",
      dataIndex: "created_by.email",
      key: "email",
      sorter: false,
      render: (row: any, record: any) => {
        return record.created_by?.email;
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (row: any, record: any) => {
        return (
          <div className="flex justify-content-between">
            {/* <Button disabled={!record.attributes || !record.attributes?.latitude || !record.attributes?.longitude} onClick={() => {
            setSplit(true)
            setAsset(record)
          }} size='small' className={`size-8  rounded-full py-3 flex items-center`} ><MapPinIcon /></Button> */}
            <VehicleDetails info={record.vehicle_info} id={record.id} />
            <PermissionCheck permission={UPDATE_FLEET}>
              <EditVehicle data={record} mutate={setMutate} />
            </PermissionCheck>
            <PermissionCheck permission={DESTROY_FLEET}>
              <Popconfirm
                title="Delete the vehicle"
                description="Are you sure to delete this vehicle?"
                onConfirm={() => onDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size="small"
                  className="bg-gray-400 size-8 rounded-full py-3 flex items-center text-white"
                >
                  <Trash2Icon />
                </Button>
              </Popconfirm>
            </PermissionCheck>
          </div>
        );
      },
    },
  ];

  return (
    <Layout title={"Fleets"}>
      {/* <Button>
            <Link to={`http://localhost:8000/api/smartcar/authorize`}>c</Link>
          </Button> */}
      <div className="flex">
        <OperationalDatatable
          mutate={mutate}
          heading="Asset"
          addBody={
            <PermissionCheck permission={CREATE_FLEET}>
              <AddVehicle mutate={setMutate} />
            </PermissionCheck>
          }
          url="api/vehicle"
          columns={columns}
          filters={{ includeCustomers: true }}
        />
        {/* <AssetAttribute onClick={() => setSplit(false)} asset={asset} expanded={split} /> */}
      </div>
    </Layout>
  );
};

export default Vehicle;

type AssetAttributeProps = {
  asset: any;
  expanded: boolean;
  onClick: any;
};
const AssetAttribute: React.FC<AssetAttributeProps> = ({
  asset,
  expanded,
  onClick,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!!asset?.attributes && expanded) {
      setLoading(false);
    }
  }, [asset, expanded]);

  if (!expanded) {
    return <></>;
  }
  return (
    <Modal
      okButtonProps={{ style: { display: "none" } }}
      width={"auto"}
      title="Vehicle"
      open={expanded}
      onCancel={onClick}
    >
      <Spin className="flex justify-start" spinning={loading}>
        <Card className="absolute z-[10000] m-5 ml-16 p-2 drop-shadow-md shadow-lg max-w-[300px]">
          {Object.entries(asset?.attributes || {}).map(
            ([key, value]: any, i) => {
              return (
                <p key={i}>
                  <span>
                    <strong>{key.toUpperCase()}</strong>: {value}{" "}
                  </span>
                </p>
              );
            }
          )}
        </Card>
        {!!asset?.attributes && (
          <LeafMap
            position={[asset.attributes.latitude, asset.attributes.longitude]}
          />
        )}
      </Spin>
    </Modal>
  );
};

type VehicleDetailsProps = {
  info: any;
  id: string;
};

const VehicleDetails: React.FC<VehicleDetailsProps> = ({ info, id }) => {
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState([] as any);

  const fetchVehicleDetails = async () => {
    const response = await client2.get(`/api/smartcar/vehicle-details/${id}`);
    if (response.status === 200) {
      setData(response.data.data);
    }
  };

  useEffect(() => {
    if (!!toggle) {
      fetchVehicleDetails();
    }
  }, [toggle]);

  return (
    <>
      <Button
        disabled={!info}
        size="small"
        className="bg-blue-400 size-8 rounded-full py-3 flex items-center text-white"
        type="primary"
        onClick={() => setToggle(true)}
      >
        <Info />
      </Button>
      <Modal
        okButtonProps={{ style: { display: "none" } }}
        title="Vehicle Info"
        open={toggle}
        onCancel={() => setToggle(false)}
      >
        {Object.entries(info || {}).map(([key, value]: any, i) => {
          return (
            <p key={i}>
              <span>
                <strong>{key.toUpperCase()}</strong>: {value}{" "}
              </span>
            </p>
          );
        })}

        {Object.entries(data || {}).map(([key, value]: any, i) => {
          return (
            <p key={i}>
              <span>
                <strong>{_.words(key).join(" ").toUpperCase()}</strong>: {value}{" "}
              </span>
            </p>
          );
        })}
      </Modal>
    </>
  );
};

import React from 'react'
import Layout from '../../components/layouts/Layout'
import Datatable from '../../components/datatable/Datatable'
import moment from 'moment';
import OperationalDatatable from '../../components/datatable/OperationalDatatable';

const Notifications = () => {
  const columns = [
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      sorter: true
    },
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true
    },
    {
      title: 'Created',
      dataIndex: 'createdTime',
      key: 'createdTime',
      sorter: true,
      render: (row: any) => (
        <>{moment(row).format('YYYY-MM-DD')}</>
      ),
    },

  ];
  return (
    <Layout title="Notifications">
      <OperationalDatatable url="api/notifications" columns={columns} />
    </Layout>
  )
}

export default Notifications
import React, { useEffect, useState } from 'react';
import Layout from '../../components/layouts/Layout';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import SelectItems from '../../components/SelectItems';
import { Button } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { client2 } from '../../operationalRequest';

const Solar = () => {
  const [chartData, setChartData] = useState({ series: [], options: {} } as any);
  const [data, setData] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(null as any)
  const [filter, setFilter] = useState([] as any);
  const { user } = useSelector((state: any) => state.user);

  const fetchData = async (i: any) => {
    setLoading(true);
    try {
      const response = await client2.get(`api/forcast/detail/${i}`);
      const apiData = response.data?.data;
      setData(apiData)    
      const dates = apiData?.index.map((timestamp: any) => new Date(timestamp));
      const uniqueDays = [...new Set(dates.map((timestamp:any) => new Date(timestamp)).map((date: any) => date.toISOString().split('T')[0]))];
      setFilter(uniqueDays.map((item: any, i:number) => {
        return {index: i, date: item, active: i===0? true: false}
      }))

    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false)
  };
  useEffect(() => {
    if(!!index?.value){
      fetchData(index.value)
    }
  }, [index]);

  useEffect(() => {
      const chartData = formatChartData(data);
      setChartData(chartData);  
  },[data, filter])

  const formatChartData = (apiData: any) => {
    // Extract necessary data from the API response and format it for ApexCharts
    let filteredData = [] as any;
    let filteredTime = [] as any;

    apiData?.index?.forEach((it:any, i:number) => {
      if(it.includes(filter.find((f:any) => f.active)?.date)){
        filteredData.push({data:apiData.data[i][0], time: it })
      }
    })
    const series = [
      {
        name: 'Power',
        data: filteredData?.map((item: any, index: any) => {
          const dateObject = new Date(item.time);
          // console.log(dateObject, filter)
          const formattedTime = dateObject
        //   toLocaleString('en-US', {
        //     hour: 'numeric',
        //     minute: 'numeric',
        //     hour12: true,
        //   });
    
          return {
            x: formattedTime,
            y: item.data,
          };
        }),
      },
    ];
    
    const options = {
        chart: {
          type: 'area',
          height: 400,
          width: '100%',
          toolbar: {
            autoSelected: 'pan',
            show: false,
          },
          
        },
        xaxis: {
          type: 'datetime',
          stepSize  :100,    
          labels: {
            show: false,
            format: 'hh:mm tt',
          },
          
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
        colors: ['#FFA500'], // Orange color
        stroke: {
          width: 1,
        },
        dataLabels: {
          enabled: false,
          offsetY: -10, // Adjust the offsetY to add spacing between labels and data points
        },
        markers: {
          size: 0,
        },
        grid: {
        show: true,
          padding: {
            top: 0,
            right: 0,
            bottom: 10,
            left: 0,
          },
        },
      };
  
    return { series, options };
  };
  return (
    <Layout title="Solar Forecast" loading={loading}>
        {/* {user?.email === 'r.kasapis@apollo-gs.com' &&  */}
        <div className="flex px-4 py-2 border rounded-md shadow-md mb-5 justify-start space-x-12">
        <SelectItems onChange={setIndex} placeholder='Select Location'/>
        {!!index?.value && <h3 className='text-xl font-semibold text-green-900'><strong>PV System</strong>: {index?.label}</h3>}
        </div>
        {/* } */}
        <div className="flex justify-start space-x-3">
          {filter.map((item:any, i:number) => {
            return(
              <Button key={i} onClick={() => setFilter((prev:any) => [...prev.map((it:any) => {
                return {...it, active: it.index === i}
            })])} type={`${item?.active ? 'dashed' : 'default'}`} disabled={item?.active}>{moment(item?.date).startOf('day').fromNow()}</Button>
            )
          })}
          
        </div>
        <div style={{ width: '100%', overflowX: 'auto' }}>
        <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={400} />
      </div>
    </Layout>
  );
};

export default Solar;
import { Modal } from "antd";
import { useEffect } from "react";
import FetchUnit from "./FetchUnit";

type ShowRemoniUnitProps = {
  setConnected: any;
  connected: boolean;
  id: number;
};

const ShowRemoniUnit: React.FC<ShowRemoniUnitProps> = ({
  setConnected,
  connected,
  id,
}) => {
  useEffect(() => {}, [connected, id]);

  return (
    <>
      <Modal
        title={"Unit List"}
        open={connected}
        onOk={() => setConnected(false)}
        onCancel={() => setConnected(false)}
        width={800}
        okButtonProps={{ style: { display: "none" } }}
      >
        <FetchUnit connected={connected} id={id} setConnected={setConnected} />
        <br />
      </Modal>
    </>
  );
};

export default ShowRemoniUnit;

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { client } from '../../useRequest'
import toastr from "toastr";
import { PlusIcon } from 'lucide-react'
import { Modal } from 'antd'
import { client2 } from '../../operationalRequest';
import EditVehicleComponent from './EditVehicleComponent';
import Smartcar from '@smartcar/auth';
import api from '../../utils/smartcarApi';
import { getPermissions } from '../../utils/carUtils';
import { config } from '../../utils/smartCarConfig';

type AddVehiclerops = {
  mutate: any
}
const AddVehicle: React.FC<AddVehiclerops> = ({ mutate }) => {
  const [vehicle, setVehicle] = useState({} as any)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);
  // const fetchOptions = async () => {
  //   const profile = await client.get(`api/assetProfileInfo/default`)
  //   setProfile(profile.data.id)
  // }

  const onComplete = (vehicleId:any) => async (err: any, code: any, state: any) => {
    if (err) {
      console.log(
        'An error occurred in the Connect flow, most likely because the user denied access'
      );
      return;
    }
    try {
      setLoading(true)
      console.log('vehicle', vehicleId, state)
      const response = await api.exchangeCode(code, vehicleId);
      // const response = await api.getVehicles();
      mutate(response.data)
      setLoading(false);
      toastr.success("Vehicle Connected Successfully");
    } catch (error: any) {
        return error
    }
  };
  
  const authorize = (vin: any, id: any) => {
    const smartcar = new Smartcar({
      clientId: process.env.REACT_APP_CLIENT_ID,
      redirectUri: process.env.REACT_APP_REDIRECT_URI,
      // set scope of permissions: https://smartcar.com/docs/api/#permissions
      scope: getPermissions(),
      mode: 'test', // one of ['live', 'test', 'simulated']
      onComplete: onComplete(id),
    });
    smartcar.openDialog({
      forcePrompt: true,
      // bypass car brand selection screen: https://smartcar.com/docs/api#brand-select
      // vehicleInfo: {
      //   make: config.brandSelect,
      // },
      // only allow users to authenticate ONE vehicle
      // singleSelect: {vin: 'OSCBMW004CEF5773C'},
      singleSelect: {vin: vin},
    })
  }

  console.log(getPermissions())

  const onSubmit = async (values: any, form: any) => {
    try {
      if (loading) return;
      setLoading((curr: any) => true)
      const response = await client2.post('api/vehicle', {
        ...values
      })
    
        mutate({ data: response.data });
        setOpen(false)
        setVehicle((curr: any) => response.data.data)
        toastr.success("Vehicle Created Successfully");
        console.log(response.data)
        if(!!response.data.data?.vin){
          authorize(response.data.data.vin, response.data.data.id)
        }

      form.resetFields();
    } catch (e: any) {
      console.log(e)
    }
    setLoading((curr: any) => false)
  }

  // useEffect(() => {
  //   fetchOptions();
  // }, [])
  return (
    <>
      <a onClick={() => setOpen(true)}>
        <PlusIcon size={30} color='white' strokeWidth={2} className=' rounded-full cursor-pointer bg-gray-500 transition duration-300 ease-in-out hover:bg-gray-400 hover:text-white' />
      </a>
      <Modal
        title={'Add Vehicle'}
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={800}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <EditVehicleComponent loading={loading} onSubmit={onSubmit} />
      </Modal>
    </>
  )
}

export default AddVehicle
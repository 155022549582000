import React, { useEffect, useState } from 'react'
import Layout from '../../components/layouts/Layout'
import { Col, Divider, Row } from 'antd'
import Chart from 'react-apexcharts';
import Notifications from '../../components/Notifications';

const Dashboard = () => {

  const [battery, setBattery] = useState({
    series: [
      {
        name: 'Battery 1',
        data: [50, 60, 55, 70, 65, 80, 75, 29, 45, 12, 89], // Initial data for Battery 1
      },
      {
        name: 'Battery 2',
        data: [30, 40, 35, 50, 45, 60, 55, 34, 24, 67, 12], // Initial data for Battery 2
      },
    ],
    options: {
      chart: {
        type: 'area',
        height: 150,
      },
      xaxis: {
        // categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7'], // Replace with your actual weeks
        title: {
          text: 'Battery Charge Level (%)',
        },
      },
      yaxis: {
      },
      legend: {
        position: 'top',
      },
      colors: ['#008FFB', '#00E396'], // Customize colors as needed
    },
  } as any);

  const data = {
    series: [
      {
        name: 'Grid Power',
        data: [350, 400, 460, 500, 550, 600, 500], // Replace with your actual data
      },
      {
        name: 'Solar Power',
        data: [550, 200, 150, 400, 450, 450, 500], // Replace with your actual data
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        borderRadius: 100, 
      },
      xaxis: {
        title: {
          text: 'Power Consumption(kWh)',
        },
        categories: ['Feb 16', 'Feb 17', 'Feb 18', 'Feb 19', 'Feb 20', 'Feb 21', 'Feb 22' ], // Replace with your actual weeks
      },
      yaxis: {
        
      },
      legend: {
        position: 'top',
      },
      colors: ['#008FFB', '#00E396'], // Customize colors as needed
      dataLabels: {
        enabled: false, // Disable data labels inside the bars
      },
    } as any,
  };

  useEffect(() => {
    // Simulate live data update (for demonstration purposes)
    const interval = setInterval(() => {
      setBattery((prevData: any) => {
        const newData = {
          ...prevData,
          series: [
            {
              name: 'Battery 1',
              data: [...prevData.series[0].data.slice(1), Math.floor(Math.random() * 100)], // Remove first element and update data for Battery 1
            },
            {
              name: 'Battery 2',
              data: [...prevData.series[1].data.slice(1), Math.floor(Math.random() * 100)], // Remove first element and update data for Battery 2
            },
          ],
        };
        return newData;
      });
    }, 5000); // Update every 2 seconds
  
    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);


  return (
    <Layout title="Dashboard">
      <Row className='flex justify-between border shadow-sm rounded mt-2 py-4 px-4' gutter={[16, 16]}>
        <Col md={4}>
          <span>Today's Consumption</span>
          <div className='flex space-x-2 mt-2'>
            <h5 className="font-semibold text-xl">10.5 kWh</h5>
            <small className='bg-green-100 px-1 my-auto'>+15.8%</small>
          </div>
          <small>Compare to yesterdays consumptions</small>
        </Col>
        <Divider className='h-20 my-auto' type="vertical" />
        <Col md={4}>
          <span>Peak Hours</span>
          <div className='flex space-x-2 mt-2'>
            <h5 className="font-semibold text-xl">6.9 kWh</h5>
            <small className='bg-red-200 px-1 my-auto'>-6.2%</small>
          </div>
          <small>08:00 - 16:00</small>
        </Col>
        <Divider className='h-20 my-auto' type="vertical" />
        <Col md={4}>
          <span>Off hours</span>
          <div className='flex space-x-2 mt-2'>
            <h5 className="font-semibold text-xl">4.2 kWh</h5>
            <small className='bg-green-100 px-1 my-auto'>+5.01%</small>
          </div>
          <small>16:00 - 03:00</small>
        </Col>
        <Divider className='h-20 my-auto' type="vertical" />
        <Col md={4}>
          <span>Efficiency Rating</span>
          <div className='flex space-x-2 mt-2'>
            <h5 className="font-semibold text-xl">42% kWh</h5>
            <small className='bg-green-100 px-1 my-auto'>+2.19%</small>
          </div>
          <small>Compare to last month</small>
        </Col>
      </Row>
      <Row className='mt-5 flex justify-between'>
        <Col md="12" className='w-auto'>
          <Chart options={data.options} series={data.series} type="bar" width="500"  height={350} />
        </Col>
        <Col md={12}>
          <h5 className='font-semibold text-lg mt-3 mb-5'>Notifications</h5>
          <Notifications/>
        </Col>
      </Row>
      <Row >
        <Col className='w-full'>
        <Chart options={battery.options} series={battery.series} type="area" height={250} />
        </Col>

      </Row>
    </Layout>
  )
}

export default Dashboard
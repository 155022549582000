import { Button, Spin } from 'antd'
import React from 'react'
import { LoadingOutlined } from '@ant-design/icons';
type LoadingButtonProps = {
    title: string,
    loading: boolean,
    type?: "primary" | "default" | "link" | "text" | "dashed"

}
const LoadingButton: React.FC<LoadingButtonProps> = ({title, loading, type='primary'}) => {
  return (
    <>
    {!loading ? <Button  type={type} htmlType={"submit"}>
        {title}
      </Button>: <Button disabled type={type} htmlType='button'><div className='text-white'><Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />}/> Loading...</div></Button>}
    </>
  )
}

export default LoadingButton
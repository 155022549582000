import { useEffect, useState } from "react";
import { Button, Spin, Table, Form as CustomForm, Checkbox } from "antd";
import { client2 } from "../../../operationalRequest";
import toastr from "toastr";
import SelectTable from "../../../components/formComponents/SelectTable";

type FetchUnitProps = {
  id: number;
  connected: boolean;
  setConnected: any;
};

const FetchUnit: React.FC<FetchUnitProps> = ({
  id,
  connected,
  setConnected,
}) => {
  const [data, setData] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [form] = CustomForm.useForm();

  const fetchUnit = async () => {
    setLoading(true);
    const response = await client2.get(`api/fetch-remoni-unit/${id}`);
    if (response.data.status === 401) {
      setConnected(false);
    }
    if (response.status === 200) {
      setConnected(true);
      setData(response.data.data);
    }
    if (response.data.status === 400) {
      toastr.error(response.data.message);
    }
    setLoading(false);
  };

  const importUnit = async () => {
    setLoading(true);
    const record = data.filter((item: any) => item.checked);
    try {
      const response = await client2.post(`api/remoni/import/${id}`, {
        ...record,
      });
      if (response.status === 200) {
        toastr.success("Sensors Imported Successfully");
        form.resetFields();
        setConnected(false);
      } else {
        toastr.error("Bad Request");
      }
    } catch (e: any) {}
    setLoading(false);
  };

  useEffect(() => {
    fetchUnit();
  }, [connected]);

  const columns = [
    {
      title: "Id",
      dataIndex: "UnitId",
      key: "UnitId",
      sorter: false,
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      sorter: false,
    },
    {
      title: "Import",
      key: "import",
      render: (row: any, record: any) => (
        <Checkbox
          checked={record.checked}
          onChange={(e) => handleCheckboxChange(e, record.UnitId)}
        />
      ),
    },
    {
      title: "Site",
      key: "site",
      render: (row: any, record: any) => (
        <SelectTable
          disabled={!record?.checked}
          table="sites"
          placeholder="Select Site"
          value={record?.site}
          onChange={(e: any) => handleInputChange(e, record.UnitId, "site")}
        />
      ),
    },
    {
      title: "System",
      key: "system",
      render: (row: any, record: any) => (
        <SelectTable
          disabled={!record?.checked}
          table="systems"
          placeholder="Select System"
          value={record?.system}
          onChange={(e: any) => handleInputChange(e, record.UnitId, "system")}
        />
      ),
    },
  ];

  const handleCheckboxChange = (e: any, key: any) => {
    const newData = data.map((item: any) => {
      if (item.UnitId === key) {
        return { ...item, checked: e.target.checked };
      }
      return item;
    });
    setData(newData);
  };

  const handleInputChange = (e: any, key: any, type: string) => {
    const newData = data.map((item: any) => {
      if (item.UnitId === key) {
        return { ...item, [`${type}`]: e };
      }
      return item;
    });
    setData(newData);
  };

  return (
    <>
      {connected && (
        <Table
          pagination={false}
          scroll={{ y: 500 }}
          loading={{
            spinning: loading,
            indicator: <Spin size="large" style={{ color: "green" }} />,
          }}
          className="w-full drop-shadow-sm shadow-md"
          dataSource={data}
          columns={columns}
          rowKey={(record: any) => record.UnitId}
        />
      )}
      <br />
      <Button onClick={importUnit}>Import</Button>
    </>
  );
};

export default FetchUnit;

import { Button, Modal, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { client2 } from "../../../operationalRequest";
import toastr from "toastr";
import { ArrowLeftCircleIcon, ArrowRightCircle, ViewIcon } from "lucide-react";
import { render } from "@testing-library/react";

type FetchPlantProps = {
    id: number,
    connected: boolean,
    setConnected: any
}
const FetchPlant: React.FC<FetchPlantProps> = ({ id, connected, setConnected }) => {

    const [data, setData] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(true);
    const [code, setCode] = useState("" as any)

    const fetchStation = async () => {
        setLoading(true)
        try {
            const response = await client2.get(`api/fetch-huawei-plant/${id}`);
            if (response.data.status === 401) {
                setConnected(false)
            }
            if (response.data?.status === 200) {
                setConnected(true);
                setData(response.data.data.data.list)
            }
            if (response.data.status === 400) {
                toastr.error(response.data.message);
            }
            setLoading(false)
        } catch (e: any) {
            // toastr.error('Bad Request');
        }
    }

    const importPlant = async () => {
        setLoading(true);
        const response = await client2.get(`api/huawei/import/${id}`)
        if (response.status === 200) {
            toastr.success("Devices Imported Successfully");
            setConnected(false);

        } else {
            toastr.error("Bad Request");

        }
        setLoading(false)
    }

    useEffect(() => {
        fetchStation();
    }, [connected])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'plantName',
            key: 'planeName',
            sorter: false
        },

        {
            title: 'Address',
            dataIndex: 'plantAddress',
            key: 'plantAddress',
            sorter: false
        },
        {
            title: 'Capacity',
            dataIndex: 'capacity',
            key: 'capacity',
            sorter: false
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (row: any, record: any) => {
                // console.log('hello', record?.plantCode)
                return <Button onClick={() => {
                    setStep(false)
                    setCode(record?.plantCode)
                }} size='small' className='bg-gray-400 size-8 rounded-full py-3 flex items-center text-blue'><ArrowRightCircle /></Button>

            }
        }
    ];

    return (
        <>
            {step ? (
                <>
                    {connected && (

                        <Table pagination={false}
                            scroll={{ y: 500 }}
                            loading={{
                                spinning: loading,
                                indicator: <Spin size="large" style={{ color: 'green' }} />,
                            }}
                            className='w-full drop-shadow-sm shadow-md'
                            dataSource={data}
                            columns={columns}

                            rowKey={(record: any) => record.plantCode}
                        // onChange={handleSort}
                        />
                    )}
                    <br />
                    <Button disabled={loading} onClick={() => {
                        importPlant();
                    }}>Import {loading && <Spin />}</Button>
                </>
            ) : (
                <ViewDevices step={"NE=153865497"} onClick={() => setStep(true)} id={id} code={code} />
            )}
        </>
    )
}

export default FetchPlant

type ViewDevicesProps = {
    step: any,
    id: any,
    onClick: any,
    code: any
}

const ViewDevices: React.FC<ViewDevicesProps> = ({ step, onClick, id, code }) => {
    const [data, setData] = useState([] as any);
    const [loading, setLoading] = useState(false)
    const fetchDevices = async () => {
        setLoading(true)
        const response = await client2.get(`api/huawei/api-setting/${id}?code=${code}`);
        console.log('fasdfas', response)
        setData(response.data.data.data)
        setLoading(false)
    }

    useEffect(() => {
        if (step && !!code) {
            fetchDevices()
        }
    }, [code])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'devName',
            key: 'devName',
            sorter: false
        },

        {
            title: 'Type',
            dataIndex: 'invType',
            key: 'invType',
            sorter: false
        },
        {
            title: 'Code',
            dataIndex: 'esnCode',
            key: 'esnCode',
            sorter: false
        },

        {
            title: 'Latitude',
            dataIndex: 'latitude',
            key: 'latitude',
            sorter: false
        },

        {
            title: 'Longitude',
            dataIndex: 'longitude',
            key: 'longitude',
            sorter: false
        },
    ];


    return (
        <>
            <Button onClick={onClick} size='small' className='bg-gray-400 size-8 rounded-full py-3 flex items-center text-blue mb-4' ><ArrowLeftCircleIcon /></Button>
            <Table pagination={false}
                scroll={{ y: 500 }}
                loading={{
                    spinning: loading,
                    indicator: <Spin size="large" style={{ color: 'green' }} />,
                }}
                className='w-full drop-shadow-sm shadow-md'
                dataSource={data}
                columns={columns}
                // footer={() => (
                //     <Pagination onChange={(page: any, pageSize: any) => setPagination({ ...pagination, page: page, pageSize })} className='flex justify-end' defaultCurrent={pagination.page} total={data?.data?.total} pageSize={pagination.pageSize} />
                // )}
                rowKey={(record: any) => record.devDn}
            // onChange={handleSort}
            />
        </>
    )
}


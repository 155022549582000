import React, { useEffect } from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

type MapLeafProps = {
    locations: any 
}

const MapLeaf: React.FC<MapLeafProps> = ({locations}) => {
    useEffect(()=> {

    },[locations])
    // console.log('fasdfasdfasd', locations   )
  return (
    <MapContainer key={JSON.stringify(locations)} center={[locations.length > 0 ? locations[0].lat : 0, locations.length > 0 ?locations[0].lng: 0]} zoom={4} style={{ height: '300px' }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {locations.map((location: any, index: number) => (
                    <Marker key={index} position={[location.lat, location.lng]}>
                        <Popup>{location.name}</Popup>
                    </Marker>
                ))}
            </MapContainer>
  )
}

export default MapLeaf
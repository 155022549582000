import React from "react";
import { Form as CustomForm, Input } from "antd";
import LoadingButton from "../../components/LoadingButton";
import _ from "lodash";
import TextArea from "antd/es/input/TextArea";
import CustomSelectOptions from "../../components/formComponents/CustomSelectOptions";
import SelectTable from "../../components/formComponents/SelectTable";
import SelectDeviceLocation from "../../components/formComponents/SelectDeviceLocation";

type EditDeviceComponentProps = {
  onSubmit: any;
  initialValue?: any;
  loading: boolean;
};

const EditDeviceComponent: React.FC<EditDeviceComponentProps> = ({
  onSubmit,
  initialValue,
  loading,
}) => {
  const [form] = CustomForm.useForm();

  return (
    <CustomForm
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      initialValues={initialValue}
      onFinish={(values: any) => onSubmit(values, form)}
      autoComplete="off"
      layout="vertical"
    >
      <CustomForm.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input system name!" }]}
      >
        <Input />
      </CustomForm.Item>

      <CustomForm.Item
        label="System Type"
        name="type"
        rules={[{ required: true, message: "Please select the system type!" }]}
      >
        <CustomSelectOptions
          options={[
            { value: "2", label: "Energy System" },
            {
              value: "1", label: "PV Energy Generation",
            },
            { value: "3", label: "Energy Storage" },
            { value: "4", label: "Energy Consumption" },
            { value: "5", label: "Grid Point" },
          ]}
          disabled={form.getFieldValue("imported")}
          onChange={(val: any) => form.setFieldValue("type", val)}
        />
      </CustomForm.Item>



      {/* <CustomForm.Item
        label="Manufacture Type"
        name="manufacture_type"
        rules={[
          { required: true, message: "Please select the manufacture type!" },
        ]}
      >
        <CustomSelectOptions
          options={[
            { value: "1", label: "Huawei" },
            { value: "2", label: "GoodWe" },
            { value: "3", label: "DEDDIE" },
            { value: "4", label: "ReMoni" },
            { value: "30", label: "Custom" },
          ]}
          disabled={form.getFieldValue("imported")}
          onChange={(val: any) => form.setFieldValue("manufacture_type", val)}
        />
      </CustomForm.Item> */}
      <CustomForm.Item
        label="System"
        name="root_id"
        rules={[
          { required: false, message: "Please select the system!" },
        ]}
      >
        <SelectTable table="systems"/>
      </CustomForm.Item>
      <CustomForm.Item
        label="Location"
        name="system_id"
        rules={[
          { required: true, message: "Please select the system!" },
        ]}
      >
        <SelectDeviceLocation
          disabled={form.getFieldValue("imported")}
          placeholder="Select Location"
          onChange={(val: any) => form.setFieldValue("system_id", val)}
          value={form.getFieldValue("system_id")}
        />
      </CustomForm.Item>
      <CustomForm.Item
        label="Description"
        name={["attributes", "description"]}
        rules={[{ required: false, message: "Please input Description!" }]}
      >
        <TextArea rows={4} placeholder="Enter Description" />
      </CustomForm.Item>

      <CustomForm.Item>
        <LoadingButton
          loading={loading}
          title={_.isEmpty(initialValue) ? "Save" : "Update"}
        />
      </CustomForm.Item>
    </CustomForm>
  );
};

export default EditDeviceComponent;

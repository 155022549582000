import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    role:{role:[], permission:[]},
    isLoading: false,
    error: ''
}

const roleSlice = createSlice({
    name: 'rolePermission',
    initialState,
    reducers: {
        getRolePending: (state) => {
            state.isLoading = true;
        },
        getRoleSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.role = payload;
            state.error = '';
        },
        getRoleFailed: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

const {reducer, actions} = roleSlice;

export const {getRolePending, getRoleSuccess, getRoleFailed} = actions;

export default reducer;
import React from 'react'
import Layout from '../components/layouts/Layout'
import { Button, Result } from 'antd'
import { useNavigate } from 'react-router-dom'

const Page404 = () => {
  const navigate = useNavigate();
  return (
    <Layout title="404">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button onClick={() => navigate('/dashboard')} type="primary">Back Dashboard</Button>}
      />
    </Layout>
  )
}

export default Page404
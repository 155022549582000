import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Login from "./page/guest/Login";
import ForgetPassword from "./page/guest/forget-password/ForgetPassword";
import Dashboard from "./page/dashboard/Dashboard";
import Profile from "./page/dashboard/Profile";
import Devices from "./page/devices/Devices";
import PrivateRoute from "./PrivateRoute";
import Page404 from "./page/Page404";
import Notifications from "./page/notifications/Notifications";
import Assets from "./page/assets/Assets";
import Solar from "./page/solar/Solar";
import Vehicle from "./page/vehicle/Vehicle";
import TestVehicle from "./page/vehicle/TestVehicle";
import Sites from "./page/sites/Sites";
import Storeys from "./page/storey/Storeys";
import Spaces from "./page/spaces/Spaces";
import Iots from "./page/iots/Iots";
import Tree from "./page/tree/Tree";
import TreeExample from "./page/tree/TreeExample";
import Connect from "./page/connect/Connect";
import Prediction from "./page/connect/Prediction";
import Huawei from "./page/connect/huawei/Huawei";
import DeviceDetails from "./page/connect/huawei/DeviceDetails";
import Settings from "./page/settings/Settings";
import Reports from "./page/report/Reports";
import { useSelector } from "react-redux";
import SuperDashboard from "./page/superadmin/dashboard/Dashboard";
import Tenants from "./page/superadmin/tenants/Tenants";
import Onboarding from "./page/guest/Onboarding";
import SubTenants from "./page/subTenants/SubTenants";
import PermissionRoute from "./PermissionRoute";
import {
  READ_API_SETTING,
  READ_BUILDING,
  READ_DASHBOARD,
  READ_PROFILE,
  READ_DEVICE,
  READ_FLEET,
  READ_FORECAST,
  READ_NOTIFICATION,
  READ_REPORT,
  READ_SITE,
  READ_SPACE,
  READ_STOREY,
  READ_SUB_TENANT,
  READ_SYSTEM,
  READ_TENANT,
} from "./utils/permission";

const AllRoutes = () => {
  const { user } = useSelector((state: any) => state.user);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path={`/${user?.role}`} element={<PrivateRoute />}>
          {(user?.role === "tenant" || user?.role === "sub-tenant") && (
            <>
              <Route
                path="dashboard"
                element={
                  <PermissionRoute pass={READ_DASHBOARD}>
                    <Dashboard />
                  </PermissionRoute>
                }
              />
              <Route
                path="profile"
                element={
                  <PermissionRoute pass={READ_PROFILE}>
                    <Profile />
                  </PermissionRoute>
                }
              />
              <Route
                path="systems"
                element={
                  <PermissionRoute pass={READ_SYSTEM}>
                    <Devices />
                  </PermissionRoute>
                }
              />
              <Route
                path="notifications"
                element={
                  <PermissionRoute pass={READ_NOTIFICATION}>
                    <Notifications />
                  </PermissionRoute>
                }
              />
              <Route
                path="buildings"
                element={
                  <PermissionRoute pass={READ_BUILDING}>
                    <Assets />
                  </PermissionRoute>
                }
              />
              <Route
                path="forecast"
                element={
                  <PermissionRoute pass={READ_FORECAST}>
                    <Solar />
                  </PermissionRoute>
                }
              />
              <Route
                path="fleets"
                element={
                  <PermissionRoute pass={READ_FLEET}>
                    <Vehicle />
                  </PermissionRoute>
                }
              />
              <Route
                path="sites"
                element={
                  <PermissionRoute pass={READ_SITE}>
                    <Sites />
                  </PermissionRoute>
                }
              />
              <Route
                path="buildings"
                element={
                  <PermissionRoute pass={READ_BUILDING}>
                    <Assets />
                  </PermissionRoute>
                }
              />
              <Route
                path="storeys"
                element={
                  <PermissionRoute pass={READ_STOREY}>
                    <Storeys />
                  </PermissionRoute>
                }
              />
              <Route
                path="spaces"
                element={
                  <PermissionRoute pass={READ_SPACE}>
                    <Spaces />
                  </PermissionRoute>
                }
              />
              <Route
                path="devices"
                element={
                  <PermissionRoute pass={READ_DEVICE}>
                    <Iots />
                  </PermissionRoute>
                }
              />
              <Route
                path="settings"
                element={
                  <PermissionRoute pass={READ_API_SETTING}>
                    <Settings />
                  </PermissionRoute>
                }
              />
              <Route
                path="reports"
                element={
                  <PermissionRoute pass={READ_REPORT}>
                    <Reports />
                  </PermissionRoute>
                }
              />
              <Route
                path="sub-tenant"
                element={
                  <PermissionRoute pass={READ_SUB_TENANT}>
                    <SubTenants />
                  </PermissionRoute>
                }
              />
              {/* <Route path="trees" element={<TreeExample />} />
              <Route path="trees2" element={<Tree />} />
              <Route path="connects" element={<Connect />} />
              <Route path="connects/huawei" element={<Huawei />} /> */}
              <Route path="predictions" element={<Prediction />} />
              {/* <Route path="connects/huawei/devices" element={<DeviceDetails />} /> */}
            </>
          )}
          {user?.role === "super-admin" && (
            <>
              <Route
                path="dashboard"
                element={
                  <PermissionRoute pass={READ_DASHBOARD}>
                    <Dashboard />
                  </PermissionRoute>
                }
              />
              <Route
                path="profile"
                element={
                  <PermissionRoute pass={READ_PROFILE}>
                    <Profile />
                  </PermissionRoute>
                }
              />
              <Route
                path="tenants"
                element={
                  <PermissionRoute pass={READ_TENANT}>
                    <Tenants />
                  </PermissionRoute>
                }
              />
            </>
          )}

          <Route path="*" element={<Page404 />} />
        </Route>
        <Route path="*" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;

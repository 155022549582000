import React, { useEffect, useState } from 'react'
import toastr from "toastr";
import { PlusIcon } from 'lucide-react'
import { Button, Modal } from 'antd'
import { client2 } from '../../operationalRequest';
import EditSettingComponent from './EditSettingComponent';
import OperationalDatatable from '../../components/datatable/OperationalDatatable';

type AddSettingProps = {
  mutate?: any
}
const AddSetting: React.FC<AddSettingProps> = ({ mutate }) => {
  const [profile, setProfile] = useState({} as any)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);
  const [connected, setConnected] = useState(false);
  const [id, setId] = useState(0 as number);
  // const fetchOptions = async () => {
  //   const profile = await client.get(`api/assetProfileInfo/default`)
  //   setProfile(profile.data.id)
  // }



  const onSubmit = async (values: any, form: any) => {
    console.log(process.env.REACT_APP_OPERATIONAL_URL,)
    try {
      if (loading) return;
      setLoading((curr: any) => true)
      const response = await client2.post('api/api-settings', {
        ...values
      })
    
        mutate({ data: response.data });
        setOpen(false)
        toastr.success(response.data.message);
      form.resetFields();
      setConnected(true);
      setId(response.data.data.id)
    } catch (e: any) {
      console.log(e)
    }
    setLoading((curr: any) => false)
  }

  // useEffect(() => {
  //   fetchOptions();
  // }, [])
  return (
    <>
      <a onClick={() => setOpen(true)}>
        <PlusIcon size={30} color='white' strokeWidth={2} className=' rounded-full cursor-pointer bg-gray-500 transition duration-300 ease-in-out hover:bg-gray-400 hover:text-white' />
      </a>
      <Modal
        title={'Add Setting'}
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={800}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <EditSettingComponent loading={loading} onSubmit={onSubmit} />

      </Modal>
    </>
  )
}

export default AddSetting


import { Select } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { client2 } from '../operationalRequest';

type SelectItemsProps = {
    placeholder?: string
    absoluteurl?: string;
    relativeurl?: string;
    onChange?: any
}
const SelectItems: React.FC<SelectItemsProps> = ({absoluteurl, relativeurl, onChange, placeholder = 'Select'}) => {

    const [options, setOptions] = useState([] as any)
    const [loading, setLoading] = useState(true);
    const fetchData = async() => {
        try{
            setLoading(true)
            const response = await client2.get('api/forcast/location');
            console.log(response);
            setOptions(response?.data?.data.index.map((item:any, i: number) => {
                return {value: item, label: response.data.data.data[i][4]}
            }))
            setLoading(false)
        }catch(e: any){

        }
    }
    useEffect(() => {
        fetchData()
    },[])

  return (
    <Select className='w-[300px]'
    showSearch
    placeholder={placeholder}
    onChange={(row: any, option: any) => onChange(option)}
    options={options}
    loading={loading}
  />
  )
}

export default SelectItems
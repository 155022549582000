import { Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { client2 } from '../../operationalRequest';
import _ from 'lodash';

type CustomSelectOptionsProps = {
  placeholder?: string;
  onChange?: any;
  value?: any;
  options: any;
  disabled?: boolean;
};

const CustomSelectOptions: React.FC<CustomSelectOptionsProps> = ({ onChange, placeholder = 'Select', value, options, disabled=false }) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');

  const handleSearch = useCallback(
    _.debounce((value) => {
      setQuery(value);
    }, 300),
    []
  );

  useEffect(() => {},[value])

  return (
    <Select
      className='w-full'
      showSearch
      placeholder={placeholder}
      options={options}
      loading={loading}
      onSearch={handleSearch}
      onChange={onChange}
      filterOption={(input: any, option: any) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      value={!!value ? options.find((it: any) => it.value == value)?.value : null}
      disabled={disabled}
    />
  );
};

export default CustomSelectOptions;

import React, { useState } from "react";
import Layout from "../../components/layouts/Layout";
import { Divider, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import ChangePasswordComponent from "./ChangePasswordComponent";
import { client2 } from "../../operationalRequest";

const Profile = () => {
  const { user } = useSelector((state: any) => state.user);
  const [loading, setLoading] = useState(false);

  const handlePasswordChange = async (values: any, form: any) => {
    try {
      if (loading) return;
      setLoading(true);
      const email = user?.email;
      if (values.password == values.confirmPassword) {
        const response = await client2.post(`api/auth/change-password`, {
          email,
          currentPassword: values.currentPassword,
          password: values.password,
          confirmPassword: values.confirmPassword,
        });
        if (response.status === 200) {
          toastr.success("Password changed successfully!");
          form.resetFields();
        }
      }
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout title={"Profile"}>
      <div className="px-8">
        <div>
          <Avatar
            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            icon={<UserOutlined />}
          />
          <p>{user.firstname + " " + user.lastname}</p>
          <p>{user?.email}</p>
        </div>
        <Divider orientation="left">Change Password</Divider>
        <ChangePasswordComponent
          loading={loading}
          onSubmit={handlePasswordChange}
        />
      </div>
    </Layout>
  );
};
export default Profile;

import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import Datatable from "../../components/datatable/Datatable";
import {
  ArrowBigLeft,
  ArrowBigRightDash,
  CrossIcon,
  Delete,
  MapPinIcon,
  SidebarCloseIcon,
  Trash2Icon,
  X,
} from "lucide-react";
import { Button, Card, Divider, Modal, Popconfirm, Spin } from "antd";
import { client } from "../../useRequest";
// import L from 'leaflet';
import AddAssets from "./AddAsset";
import EditAsset from "./EditAsset";
import toastr from "toastr";
import OperationalDatatable from "../../components/datatable/OperationalDatatable";
import LeafMap from "../../components/LeafMap";
import { client2 } from "../../operationalRequest";
import { random } from "lodash";
import MapLeaf from "../../components/MapLeaf";
import {
  CREATE_BUILDING,
  UPDATE_BUILDING,
  DESTROY_BUILDING,
} from "../../utils/permission";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";

const Assets = () => {
  const [split, setSplit] = useState(false);
  const [asset, setAsset] = useState(null as any);
  const [mutate, setMutate] = useState({} as any);
  const [locations, setLocations] = useState([] as any);

  const onDelete = async (id: any) => {
    const response = await client2.delete(`api/building/${id}`);
    // if(response.status === 200){
    setMutate({ data: Math.random() });
    toastr.success("Asset Deleted Successfully");
    // }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Created By",
      dataIndex: "created_by.email",
      key: "email",
      sorter: false,
      render: (row: any, record: any) => {
        return record.created_by?.email;
      },
    },
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      sorter: true,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      render: (row: any, record: any) => {
        return <>{record.attributes?.country}</>;
      },
    },
    {
      title: "Street Name",
      dataIndex: "street_name",
      key: "street_name",
      render: (row: any, record: any) => {
        return <>{record.attributes?.street_name}</>;
      },
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (row: any, record: any) => {
        return <>{record.attributes?.city}</>;
      },
    },
    {
      title: "Facility Manager",
      dataIndex: "ownerName",
      key: "ownerName",
      render: (row: any) => {
        <div></div>;
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (row: any, record: any) => {
        return (
          <div className="flex justify-content-between">
            <PermissionCheck permission={UPDATE_BUILDING}>
              <EditAsset data={record} mutate={setMutate} />
            </PermissionCheck>
            <PermissionCheck permission={DESTROY_BUILDING}>
              <Popconfirm
                title="Delete the building"
                description="Are you sure to delete this building?"
                onConfirm={() => onDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size="small"
                  className="bg-gray-400 size-8 rounded-full py-3 flex items-center text-white"
                >
                  <Trash2Icon />
                </Button>
              </Popconfirm>
            </PermissionCheck>
          </div>
        );
      },
    },
  ];

  const handleInfo = (values: any) => {
    setLocations(
      values?.data?.data
        .filter((it: any) => {
          // console.log('attributes', !!it.attributes && !!it.attributes?.latitude, it.attributes)
          return (
            !!it.attributes &&
            it.attributes?.latitude &&
            it.attributes?.longitude
          );
        })
        .map((item: any, i: number) => {
          return {
            lat: item.attributes?.latitude,
            lng: item.attributes?.longitude,
            name: item.name,
          };
        })
    );
  };

  return (
    <Layout
      title={"Buildings"}
      breadcrumbs={["sites", "buildings", "storeys", "spaces"]}
    >
      <div className="flex">
        <OperationalDatatable
          setInfo={handleInfo}
          mutate={mutate}
          heading="Asset"
          addBody={
            <PermissionCheck permission={CREATE_BUILDING}>
              <AddAssets mutate={setMutate} />
            </PermissionCheck>
          }
          url="api/building"
          columns={columns}
          filters={{ includeCustomers: true }}
        />
      </div>
    </Layout>
  );
};

export default Assets;

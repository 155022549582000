import React, { useState } from "react";
import { Form, Input } from "antd";
import LoadingButton from "../../../components/LoadingButton";

type ForgetPasswordComponentProps = {
  onSubmit: any;
  initialValue?: any;
  loading: boolean;
};

const ForgetPasswordComponent: React.FC<ForgetPasswordComponentProps> = ({
  onSubmit,
  initialValue,
  loading,
}) => {
  const [form] = Form.useForm();

  return (
    <>
      <h2 className="font-bold text-2xl text-green-800">Forgot password?</h2>
      <p className="text-xs mt-4 text-green-800"></p>
      <Form
        form={form}
        name="basic"
        // labelCol={{ span: 8 }}
        initialValues={initialValue}
        onFinish={(values: any) => onSubmit(values, form)}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Enter your Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter a valid email address!",
            },
          ]}
        >
          <Input
            type="email"
            name="email"
            style={{ height: "42px", width: "236px", fontSize: "16px" }}
          />
        </Form.Item>
        <Form.Item>
          <LoadingButton loading={loading} title={"Reset Password"} />
        </Form.Item>
      </Form>
    </>
  );
};
export default ForgetPasswordComponent;

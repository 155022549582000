import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import Datatable from "../../components/datatable/Datatable";
import {
  ArrowBigLeft,
  ArrowBigRightDash,
  CrossIcon,
  Delete,
  MapPinIcon,
  SidebarCloseIcon,
  Trash2Icon,
  X,
} from "lucide-react";
import { Button, Card, Divider, Modal, Popconfirm, Spin } from "antd";
import toastr from "toastr";
import OperationalDatatable from "../../components/datatable/OperationalDatatable";
import { client2 } from "../../operationalRequest";
import EditStorey from "./EditIot";
import AddStorey from "./AddIot";
import EditSpace from "./EditIot";
import {
  CREATE_DEVICE,
  DESTROY_DEVICE,
  UPDATE_DEVICE,
} from "../../utils/permission";
import PermissionCheck from "../../components/authorizeComponent/PermissionCheck";

const Iots = () => {
  const [split, setSplit] = useState(false);
  const [asset, setAsset] = useState(null as any);
  const [mutate, setMutate] = useState({} as any);
  const [locations, setLocations] = useState([] as any);

  const onDelete = async (id: any) => {
    const response = await client2.delete(`api/device/${id}`);
    // if(response.status === 200){
    setMutate({ data: Math.random() });
    toastr.success("Device Deleted Successfully");
    // }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "System",
      dataIndex: "system",
      key: "system",
      sorter: false,
      render: (row: any, record: any) => record.system?.name,
    },
    {
      title: "Manufacture",
      dataIndex: "host_type",
      key: "host_type",
      sorter: false,
      render: (row: any, record: any) => {
        switch (record.manufacture_type) {
          case 1:
            return "Huawei";
          case 2:
            return "GoodWe";
          case 3:
            return "DEDDIE";
          case 4:
            return "ReMoni";
          default:
            return "Custom";
        }
      },
    },
    {
      title: "Created By",
      dataIndex: "created_by.email",
      key: "email",
      sorter: false,
      render: (row: any, record: any) => {
        return record.created_by?.email;
      },
    },
    {
      title: "Device Type",
      dataIndex: "host_type",
      key: "host_type",
      sorter: false,
      render: (row: any, record: any) => {
        const deviceId = record.device_type;
        switch (deviceId) {
          case 10:
            return "Inverter";
          case 20:
            return "Grid Electrical Meter";
          case 30:
            return "Electrical Meter";
          case 40:
            return "Battery";
          case 50:
            return "Load";
          case 60:
            return "Sensor";
          default:
            return "Unknown";
        }
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (row: any, record: any) => {
        return (
          <div className="flex justify-content-between">
            <PermissionCheck permission={UPDATE_DEVICE}>
              <EditSpace data={record} mutate={setMutate} />
            </PermissionCheck>
            <PermissionCheck permission={DESTROY_DEVICE}>
              <Popconfirm
                title="Delete the storey"
                description="Are you sure to delete this device?"
                onConfirm={() => onDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size="small"
                  className="bg-gray-400 size-8 rounded-full py-3 flex items-center text-white"
                >
                  <Trash2Icon />
                </Button>
              </Popconfirm>
            </PermissionCheck>
          </div>
        );
      },
    },
  ];

  const handleInfo = (values: any) => {
    setLocations(
      values?.data?.data
        .filter((it: any) => {
          // console.log('attributes', !!it.attributes && !!it.attributes?.latitude, it.attributes)
          return (
            !!it.attributes &&
            it.attributes?.latitude &&
            it.attributes?.longitude
          );
        })
        .map((item: any, i: number) => {
          return {
            lat: item.attributes?.latitude,
            lng: item.attributes?.longitude,
            name: item.name,
          };
        })
    );
  };

  return (
    <Layout title={"Devices"}>
      <div className="flex">
        <OperationalDatatable
          setInfo={handleInfo}
          mutate={mutate}
          heading="Asset"
          addBody={
            <PermissionCheck permission={CREATE_DEVICE}>
              <AddStorey mutate={setMutate} />
            </PermissionCheck>
          }
          url="api/device"
          columns={columns}
          filters={{ includeCustomers: true }}
        />
      </div>
    </Layout>
  );
};

export default Iots;

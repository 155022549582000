import { Modal } from "antd"
import { useEffect } from "react"
import FetchPlant from "./FetchPlant"

type ShowDeddiePlantProps = {
    setConnected: any,
    connected: boolean,
    id: number
  }
  const ShowDeddiePlant: React.FC<ShowDeddiePlantProps> = ({setConnected, connected, id}) => {
    useEffect(() => {
      
    }, [connected, id])
    return(
      <>
        <Modal
          title={'Plant List'}
          open={connected}
          onOk={() => setConnected(false)}
          onCancel={() => setConnected(false)}
          width={800}
          okButtonProps={{ style: { display: 'none' } }}
        >
          <FetchPlant connected={connected} id={id} setConnected={setConnected} /> <br />
        </Modal>
      </>
    )
  }

  export default ShowDeddiePlant;
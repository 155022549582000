import React, { useState } from "react";
import { Button, Drawer, Tabs } from "antd";
import toastr from "toastr";
import { client2 } from "../../operationalRequest";
import { ArrowBigRightDash } from "lucide-react";
import EditDeviceComponent from "./EditDeviceComponent";

type EditDeviceProps = {
  data: any;
  mutate?: any;
};
const EditDevice: React.FC<EditDeviceProps> = ({ data, mutate }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const editDevice = async (values: any) => {
    try {
      if (loading) return;
      setLoading((curr: any) => true);
      const response = await client2.put(`api/systems/${data?.id}`, {
        system: values,
      });
      if (response.status === 200) {
        mutate({ data: "data" });
        setOpen(false);
        toastr.success("System Updated Successfully!");
      }
    } catch (e: any) {
      console.log(e);
    }
    setLoading((curr: any) => false);
  };

  return (
    <>
      <Button
        onClick={showDrawer}
        size="small"
        className={`bg-blue-500 size-8 rounded-full py-3 flex items-center`}
      >
        <ArrowBigRightDash />
      </Button>
      <Drawer size="large" title="Edit System" onClose={onClose} open={open}>
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: "System",
              key: "1",
              children: (
                <EditDeviceComponent
                  loading={loading}
                  initialValue={{ ...data, system_id: !!data.system_id? `${data.system_id}|${data.system_string_type}`: null }}
                  onSubmit={editDevice}
                />
              ),
            },
          ]}
        />
      </Drawer>
    </>
  );
};

export default EditDevice;

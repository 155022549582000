import React from "react";
import { useSelector } from "react-redux";
import PermissionDeny from "./page/PermissionDeny";

const PermissionRoute = ({
  pass,
  children,
}: {
  pass: string;
  children: any;
}) => {
  const { role } = useSelector((state: any) => state.role);

  if (!role.permissions.includes(pass)) {
    return <PermissionDeny />;
  }

  return children;
};

export default PermissionRoute;